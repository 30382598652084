@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200&display=swap'); 

*{
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  font-family: "poppins";
/* 
  font-family: 'Nunito', sans-serif;
font-family: 'Poppins', sans-serif; */
}
