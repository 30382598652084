.cat{
    margin: 75px 50px 0px 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* border: 1px solid rebeccapurple; */
    grid-gap: 50px;
}
.cat-card{
    width: calc(100/3);
    height: 500px;
    position: relative;
}
.cat-card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cat-body{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: #fff;
    background: rgba(0,0,0,0.5);
    padding: 20px 10px 20px 10px;
}
.cat-body h1{
    font-size: 35px;
    text-transform: capitalize;
}

@media screen and (max-width:840px){
    .cat{
        margin: 50px 50px 0px 50px;
        grid-template-columns: 1fr;
        /* border: 1px solid rebeccapurple; */
        grid-gap: 25px;
    }
}
@media screen and (max-width:500px){
    .cat-card{
        height:400px;
    }
}